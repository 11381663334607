import React, { useEffect } from 'react'
import classNames from 'classnames'

import style from './style.module.scss'

const Loading = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
      <div className="container h-100 vh-100">
        <div className="row h-100 justify-content-center align-items-center">
          <div className={classNames(`${style.loading}`)}>
            <div className={classNames(`${style.bounceballLoading}`)}> </div>
            <div className={classNames(`${style.textLoading}`)}>estamos cargando...</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Loading
