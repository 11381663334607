import store from 'store'
import { decodeString } from 'utils'

import actions from './actions'

const localStorage = store.get('app.request.loan')

const { key, userInfo, origenRequest, loanInfoRequest, offerRequest, lastStep } = localStorage
  ? decodeString(JSON.parse(JSON.stringify(localStorage)))
  : {}

const initialState = {
  key: key || undefined,
  userInfo: userInfo || {
    ci: undefined,
    mobilePhone: undefined,
    birthday: undefined,
    email: undefined,
    loanPurpose: undefined,
    agreeTerms: undefined,

    sex: undefined,
    address: undefined,
    location: undefined,
    city: undefined,
    occupation: undefined,
    education: undefined,
    dateEmployment: undefined,
    incomeNominal: undefined,
    income: undefined,
    marital: undefined,
    secondPhone: undefined,
    clearing: undefined,

    typeCi: undefined,
    codCi: undefined,
    codCoupon: undefined,
    codVerification: undefined,

    firstName: undefined,
    lastName: undefined,

    identityResponses: undefined,
    attachments: undefined,
  },
  origenRequest: origenRequest || {
    urlReference: undefined,
    urlReferrer: undefined,
    ipAccess: undefined,
    flowType: undefined,
    flowStep: undefined,
    device: undefined,
    origen: undefined,
  },
  loanInfoRequest: loanInfoRequest || {
    amount: undefined,
  },
  offerRequest: offerRequest || {
    amount: undefined,
    numberOfInstallments: undefined,
    moreAmount: undefined,
    dateDue: undefined,
    insurances: undefined,
  },
  apiResponse: undefined,
  lastUpdate: undefined,
  lastStep: lastStep || undefined,
  reportDate: undefined,
  processDate: undefined,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.CLEAR_STATE:
      return initialState
    default:
      return state
  }
}
