import CryptoJS from 'crypto-js'
/* eslint-disable */
export function validateDocument(ci) {
  ci = ci.replace(/\D/g, '')
  const dig = ci[ci.length - 1]
  ci = ci.replace(/[0-9]$/, '')
  return dig === getValidationNumber(ci) && ci.length === 7
}

function getValidationNumber(ci) {
  let a = 0
  let i = 0
  if (ci.length <= 6) {
    for (i = ci.length; i < 7; i++) {
      ci = `0${ci}`
    }
  }
  for (i = 0; i < 7; i++) {
    a += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10
  }
  if (a % 10 === 0) {
    return '0'
  }

  return (10 - (a % 10)).toString()
}

export function formatAmount(amount) {
  return amount.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const sleep = (ms) => new Promise((r) => setTimeout(r, ms))

export const dateFormat = [
  'DD/MM/YYYY',
  'DD/M/YYYY',
  'D/MM/YYYY',
  'DD/MM/YY',
  'DDMMYYYY',
  'DDMYYYY',
  'DMMYYYY',
  'DDMMYY',
]

export const encodeString = (str) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify(str),
    process.env.REACT_APP_SECRET_ENCRYPT_LOCALSTORAGE,
  ).toString()
}

export const decodeString = (str) => {
  const bytes = CryptoJS.AES.decrypt(str, process.env.REACT_APP_SECRET_ENCRYPT_LOCALSTORAGE)
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

  return decryptedData
}
