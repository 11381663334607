import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import Footer from 'components/loans/layout/Footer'
import React from 'react'

const mapStateToProps = () => ({})

const MainLayout = ({ children }) => {
  return (
    <div>
      <Layout>
        <Layout>
          <Layout.Content style={{ height: '100%', position: 'relative' }}>
            <div className="cred__utils__content">{children}</div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </div>
  )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
