import React from 'react'

import style from './style.module.scss'

const Footer = () => {
  return (
    <div className={style.footerInner}>
      <p className="mt-1">
        DEVUY SAS - (en adelante “TUEFECTIVO”) Préstamos personales en efectivo, con un monto mínimo
        de $5.000 pesos uruguayos y un monto máximo de $100.000 pesos uruguayos. El plazo mínimo de
        los préstamos es de 3 meses y como máximo 36 meses. TEA (Tasa Efectiva Anual): entre 103,00%
        y 130,00% o en su defecto el monto máximo autorizado por el Banco Central del Uruguay para
        cada franja al momento del desembolso de cada préstamo. La variación de la tasa se determina
        por el monto y del plazo de cada préstamo. Cargo de otorgamiento: 40 UI + IVA. Seguro de
        vida obligatorio*: 0,6‰ (seis por mil) sobre saldo. En caso de atraso en el pago de las
        cuotas y sin perjuicio del devengamiento de los intereses moratorios pactados en el Vale, la
        empresa aplicará y cobrará al cliente Gastos de Gestión de Cobranza que ascienden a 8 UI +
        IVA por cada cuota (con un máximo por operación de 80 UI + IVA). *La cobertura de los saldos
        del crédito mediante seguro de vida es condición excluyente para la otorgación de un crédito
        por parte de TUEFECTIVO. Para la comodidad del cliente, TUEFECTIVO incluye en sus préstamos
        los seguros de SURA SEGUROS S.A. (cuyo tomador es DEVUY SAS.) a los efectos de garantizar la
        cobertura, total o parcial, de la deuda contraída con TUEFECTIVO, en caso de fallecimiento o
        invalidez total y permanente. Todos los costos, tasas y precios aquí detallados son a título
        informativo, no constituyen una oferta y pueden variar al momento de solicitarlos en función
        del análisis crediticio hecho respecto a cada cliente. Asimismo están sujetos a políticas de
        mercado y/o internas de la empresa. Autorizo a TUEFECTIVO a que utilice (por sí mismo o con
        sus socios de negocios) mis datos personales a los solos efectos del análisis crediticio
        para el otorgamiento de préstamos en efectivo, órdenes de compra y tarjetas de crédito.
      </p>
    </div>
  )
}

export default Footer
