import firebase from 'firebase/app'
import 'firebase/app-check'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/performance'
import 'firebase/storage'

import moment from 'moment'

import SentryHandleError from 'services/sentry'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const appCheck = firebase.appCheck()
appCheck.activate(process.env.REACT_APP_TOKEN_CHECK)

firebase.performance()

export const firebaseFunctions = firebase.functions()
if (process.env.NODE_ENV === 'development') {
  firebaseFunctions.useEmulator('localhost', 5001)
}

export const firebaseDatabase = firebase.database()

export const firebaseStorage = firebase.storage

export async function saveLead(payload) {
  const {
    key,
    userInfo,
    origenRequest,
    loanInfoRequest,
    offerRequest,
    identityResponses,
    apiResponse,
    processDate,
  } = payload

  const dataToSave = JSON.parse(
    JSON.stringify({
      userInfo,
      origenRequest,
      loanInfoRequest,
      offerRequest,
      identityResponses,
      lastStep: origenRequest.flowStep,
      lastUpdate: moment().local().format('YYYY-MM-DD HH:mm:ss'),
      apiResponse,
      processDate,
    }),
  )

  if (key) {
    await firebaseDatabase
      .ref(`/databases/${process.env.REACT_APP_ENV}/requests`)
      .child(key)
      .set(dataToSave)

    return { key, dataToSave }
  }

  const doc = await firebaseDatabase
    .ref(`/databases/${process.env.REACT_APP_ENV}/requests`)
    .push(dataToSave)

  return { key: doc.key, doc }
}

export async function queryProposal(payload) {
  return firebaseFunctions
    .httpsCallable('queryProposal', { timeout: 120000 })(payload)
    .then()
    .catch((error) => SentryHandleError(error))
}

export async function queryOffer(payload) {
  return firebaseFunctions
    .httpsCallable('queryOffer', { timeout: 120000 })(payload)
    .then()
    .catch((error) => SentryHandleError(error))
}

export async function documentVerification(payload) {
  return firebaseFunctions
    .httpsCallable('documentVerification', { timeout: 120000 })(payload)
    .then()
    .catch((error) => SentryHandleError(error))
}

export async function mobileVerification(payload) {
  return firebaseFunctions
    .httpsCallable('mobileVerification', { timeout: 120000 })(payload)
    .then()
    .catch((error) => SentryHandleError(error))
}

export async function identityVerification(payload) {
  return firebaseFunctions
    .httpsCallable('identityVerification', { timeout: 120000 })(payload)
    .then()
    .catch((error) => SentryHandleError(error))
}

export async function createLoan(payload) {
  return firebaseFunctions
    .httpsCallable('createLoan', { timeout: 120000 })(payload)
    .then()
    .catch((error) => SentryHandleError(error))
}
