import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import settings from './settings/reducers'
import request from './request/reducers'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    settings,
    request,
  })
