import React from 'react'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'
import Navbar from '../../components/loans/layout/Navbar'
import Footer from '../../components/loans/layout/Footer'

const mapStateToProps = ({ settings }) => ({
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  isMobileView: settings.isMobileView,
})

const RequestLoanLayout = ({ children, isMobileView }) => {
  return (
    <Layout>
      <Layout.Content>
        <div className={classNames(`p-3 shadow-sm row justify-content-center`)}>
          <Navbar />
        </div>

        <div className="d-flex justify-content-center h-100">
          {/* <div className={classNames(`col-md-6 ${style.left} d-md-block d-none`)}>
            <img
              className="sticky-top "
              style={{ width: '100%' }}
              src={`${process.env.PUBLIC_URL}/resources/images/banner-web-formulario.png`}
              alt="Banner"
              loading="lazy"
            />
          </div> */}

          <div
            className={classNames(
              `col-md-6 center-block ${style.shadowRight} ${style.right} min-vh-100`,
            )}
          >
            <div className={`${isMobileView ? 'p-3' : 'p-5'}`}>{children}</div>
            <div className={classNames(`col mt-5 mb-5 center-block`)}>
              <Footer />
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(RequestLoanLayout))
