// import Loader from 'components/loans/layout/Loader'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { withRouter, Redirect } from 'react-router-dom'
import React, { Fragment } from 'react'
import RequestLoanLayout from './RequestLoanLayout'

import MainLayout from './Main'

const Layouts = {
  public: RequestLoanLayout,
  main: MainLayout,
  request: RequestLoanLayout,
}

const mapStateToProps = ({ user }) => ({ user })
let previousPath = ''

const Layout = ({ user, children, location: { pathname, search } }) => {
  // ScrollTop Management
  const currentPath = pathname + search
  if (currentPath !== previousPath) {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }
  setTimeout(() => {
    previousPath = currentPath
  }, 300)

  // Layout Rendering
  const getLayout = () => {
    if (/^\/solicitar-prestamo(?=\/|$)/i.test(pathname)) {
      return 'request'
    }
    return 'main'
  }

  const Container = Layouts[getLayout()]
  const isUserLoading = user.loading
  const isLoanRequestLayout = getLayout() === 'request'

  const BootstrappedLayout = () => {
    if (!isLoanRequestLayout) {
      return <Redirect to="/solicitar-prestamo/monto" />
    }
    if (isLoanRequestLayout) {
      return <Container>{children}</Container>
    }
    // show loader when user in check authorization process, not authorized yet and not on login pages
    if (isUserLoading) {
      return null
    }
    return <Container>{children}</Container>
  }

  return (
    <Fragment>
      <Helmet
        titleTemplate="tuefectivo.uy – Solicita tu préstamo | %s"
        title="tuefectivo.uy – Solicita tu préstamo"
      />
      {BootstrappedLayout()}
    </Fragment>
  )
}

export default withRouter(connect(mapStateToProps)(Layout))
