import React from 'react'

import style from './style.module.scss'

const Navbar = () => {
  return (
    <div>
      <nav className={style.nav}>
        <a className={style.logo} href="#">
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/logo.png`}
            alt="TUEFECTIVO"
            loading="lazy"
          />
        </a>
      </nav>
    </div>
  )
}

export default Navbar
