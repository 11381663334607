const actions = {
  SET_STATE: 'request/SET_STATE',
  SET_LOADING: 'request/SET_LOADING',
  SAVE_LEAD: 'request/SAVE_LEAD',
  QUERY_PROPOSAL: 'request/QUERY_PROPOSAL',
  QUERY_OFFER: 'request/QUERY_OFFER',
  DOCUMENT_VERIFICATION: 'request/DOCUMENT_VERIFICATION',
  MOBILE_VERIFICATION: 'request/MOBILE_VERIFICATION',
  IDENTITY_VERIFICATION: 'request/IDENTITY_VERIFICATION',
  CREATE_LOAN: 'request/CREATE_LOAN',
  CHANGE_MOBILE: 'request/CHANGE_MOBILE',
  CLEAR_STATE: 'request/CLEAR_STATE',
}

export default actions
