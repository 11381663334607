import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { store as reduxStore, history } from 'index'
import * as firebase from 'services/firebase'
import moment from 'moment'
import publicIp from 'public-ip'
import store from 'store'

import { encodeString } from 'utils'
// import SentryHandleError from 'services/sentry'

import actions from './actions'

const isLoading = (loading) => {
  reduxStore.dispatch({
    type: 'user/SET_STATE',
    payload: {
      loading,
    },
  })
}

export function* SAVE_LEAD({ payload }) {
  const { userInfo, origenRequest, loanInfoRequest, offerRequest, apiResponse, processDate } =
    payload

  yield isLoading(true)

  yield store.set(
    `app.request.loan`,
    encodeString(
      JSON.parse(
        JSON.stringify({
          ...payload,
          lastUpdate: moment().local().format('YYYY-MM-DD HH:mm:ss'),
        }),
      ),
    ),
  )

  const response = yield call(firebase.saveLead, payload)
  if (response) {
    const { key, lastStep } = response

    const dataToSave = {
      key,
      userInfo,
      origenRequest,
      loanInfoRequest,
      offerRequest,
      lastStep,
      apiResponse,
      processDate,
      lastUpdate: moment().local().format('YYYY-MM-DD HH:mm:ss'),
    }

    reduxStore.dispatch({
      type: 'request/SET_STATE',
      payload: dataToSave,
    })

    yield store.set(`app.request.loan`, encodeString(JSON.parse(JSON.stringify(dataToSave))))
  }

  yield isLoading(false)
}

export function* QUERY_PROPOSAL({ payload }) {
  yield isLoading(true)

  let ipAccess

  try {
    ipAccess = yield publicIp.v4()
  } catch (error) {
    console.log('error', error)
  }

  payload = {
    ...payload,
    origenRequest: {
      ...payload.origenRequest,
      ipAccess,
    },
  }

  const MAX_RETRIES = 3
  let attempt = 0
  let success = false
  let response

  while (attempt < MAX_RETRIES && !success) {
    try {
      response = yield firebase.queryProposal(payload)
      success = true
    } catch (error) {
      attempt += 1
      if (attempt >= MAX_RETRIES) {
        history.push('/solicitar-prestamo/error')

        yield isLoading(false)

        notification.warning({
          message: 'Se produjo un error, inténtelo de nuevo en unos minutos.',
        })

        throw error
      }
    }
  }

  if (!response) {
    yield isLoading(false)

    notification.warning({
      message: 'Se produjo un error, inténtelo de nuevo en unos minutos.',
    })

    throw new Error('No response received')
  }

  const { data } =
    typeof response?.data !== 'undefined' && response?.data && response?.data
      ? response?.data
      : undefined

  if (data) {
    const state =
      typeof data?.Resolucion?.Estado !== 'undefined' &&
      data?.Resolucion?.Estado &&
      data.Resolucion.Estado

    if (state === 0 || state === 1) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            queryProposal: data,
          },
        },
      })

      history.push('/solicitar-prestamo/error')
    }

    if (state === 4) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            queryProposal: data,
          },
        },
      })

      history.push('/solicitar-prestamo/sinOferta')
    }

    if (state === 6 || state === 7) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          userInfo: {
            ...payload.userInfo,
            firstName: data.Detalle?.Nombre,
            lastName: data.Detalle?.Apellido,
          },
          apiResponse: {
            ...payload.apiResponse,
            queryProposal: data,
          },
        },
      })

      history.push('/solicitar-prestamo/datosPersonales1')
    }
  }

  if (!data || typeof data?.Resolucion?.Estado === 'undefined') {
    yield isLoading(false)
    notification.warning({
      message: 'Se produjo un error, inténtelo de nuevo en unos minutos.',
    })

    throw new Error('Invalid data received')
  }

  yield isLoading(false)
}

export function* QUERY_OFFER({ payload }) {
  yield isLoading(true)

  const response = yield firebase
    .queryOffer(payload)
    .then()
    .catch((error) => {
      console.log('error', error)
      history.push('/solicitar-prestamo/error')
    })

  const { data } =
    typeof response?.data !== 'undefined' && response?.data && response?.data
      ? response?.data
      : undefined

  if (data) {
    const state =
      typeof data?.Resolucion?.Estado !== 'undefined' &&
      data?.Resolucion?.Estado &&
      data.Resolucion.Estado

    if (state === 0 || state === 1) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            queryOffer: data,
          },
        },
      })

      history.push('/solicitar-prestamo/error')
    }

    if (state === 4) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            queryOffer: data,
          },
        },
      })

      history.push('/solicitar-prestamo/sinOferta')
    }

    if (state === 6 || state === 7) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            queryOffer: data,
          },
        },
      })

      history.push('/solicitar-prestamo/montosAprobados')
    }
  }

  if (!data || typeof data?.Resolucion?.Estado === 'undefined') {
    yield isLoading(false)
    notification.warning({
      message: 'Se produjo un error, inténtelo de nuevo en unos minutos.',
    })
  }
}

export function* DOCUMENT_VERIFICATION({ payload }) {
  yield isLoading(true)

  const response = yield firebase
    .documentVerification(payload)
    .then()
    .catch((error) => {
      console.log('error', error)
      history.push('/solicitar-prestamo/error')
    })

  const { data } =
    typeof response?.data !== 'undefined' && response?.data && response?.data
      ? response?.data
      : undefined

  if (data) {
    const state =
      typeof data?.Resolucion?.Estado !== 'undefined' &&
      data?.Resolucion?.Estado &&
      data.StatusRequest.State

    if (state === 0 || state === 1) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            documentVerification: data,
          },
        },
      })

      history.push('/solicitar-prestamo/error')
    }

    if (state === 4 || state === 6 || state === 7) {
      const dataToSave = {
        ...payload,
        apiResponse: {
          ...payload.apiResponse,
          documentVerification: data,
        },
      }

      yield put({
        type: 'request/SAVE_LEAD',
        payload: dataToSave,
      })

      yield put({
        type: 'request/MOBILE_VERIFICATION',
        payload: dataToSave,
      })
    }
  }

  if (!data || typeof data?.Resolucion?.Estado === 'undefined') {
    yield isLoading(false)
    notification.warning({
      message: 'Se produjo un error, inténtelo de nuevo en unos minutos.',
    })
  }
}

export function* MOBILE_VERIFICATION({ payload }) {
  yield isLoading(true)

  const codVerification = (Math.floor(Math.random() * 1000000) + 1000000).toString().substring(1)

  const response = yield firebase
    .mobileVerification({
      userInfo: {
        ...payload.userInfo,
        codVerification,
      },
    })
    .then()
    .catch((error) => {
      console.log('error', error)
      history.push('/solicitar-prestamo/error')
    })

  const { data } =
    typeof response?.data !== 'undefined' && response?.data && response?.data
      ? response?.data
      : undefined

  if (data) {
    const state =
      typeof data?.Resolucion?.Estado !== 'undefined' &&
      data?.Resolucion?.Estado &&
      data.Resolucion.Estado

    if (state === 0 || state === 1 || state === 4) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            mobileVerification: data,
          },
        },
      })

      history.push('/solicitar-prestamo/error')
    }

    if (state === 7) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          userInfo: {
            ...payload.userInfo,
            codVerification,
          },
          apiResponse: {
            ...payload.apiResponse,
            mobileVerification: data,
          },
        },
      })

      history.push('/solicitar-prestamo/verificacionCelular')
    }
  }

  if (!data || typeof data?.Resolucion?.Estado === 'undefined') {
    yield isLoading(false)
    notification.warning({
      message: 'Se produjo un error, inténtelo de nuevo en unos minutos.',
    })
  }
}

/*
export function* IDENTITY_VERIFICATION({ payload }) {
  yield isLoading(true)

  const response = yield firebase
    .identityVerification(payload)
    .then()
    .catch((error) => {
      console.log('error', error)
      history.push('/solicitar-prestamo/error')
    })

  const { data } =
    typeof response?.data !== 'undefined' && response?.data && response?.data
      ? response?.data
      : undefined

  if (data) {
    const state =
      typeof data?.Resolucion?.Estado !== 'undefined' &&
      data?.Resolucion?.Estado &&
      data.Resolucion.Estado

    if (state === 0 || state === 1) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            identityVerification: data,
          },
        },
      })

      history.push('/solicitar-prestamo/error')
    }

    if (state === 4 || state === 6 || state === 7) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            identityVerification: data,
          },
        },
      })

      if (data?.Preguntas && data?.Preguntas.length > 0)
        history.push('/solicitar-prestamo/verificacionIdentidad')
      else history.push('/solicitar-prestamo/adicionales')
    }
  }

  if (!data || typeof data?.Resolucion?.Estado === 'undefined') {
    yield isLoading(false)
    notification.warning({
      message: 'Se produjo un error, inténtelo de nuevo en unos minutos.',
    })
  }
}
*/

export function* CREATE_LOAN({ payload }) {
  yield isLoading(true)

  const response = yield firebase
    .createLoan(payload)
    .then()
    .catch((error) => {
      console.log('error', error)
      history.push('/solicitar-prestamo/error')
    })

  const { data } =
    typeof response?.data !== 'undefined' && response?.data && response?.data
      ? response?.data
      : undefined

  if (data) {
    const state =
      typeof data?.Resolucion?.Estado !== 'undefined' &&
      data?.Resolucion?.Estado &&
      data.Resolucion.Estado

    if (state === 0 || state === 1) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            createLoan: data,
          },
        },
      })

      const dataToSave = {
        apiResponse: {
          createLoan: data,
        },
        lastUpdate: moment().local().format('YYYY-MM-DD HH:mm:ss'),
      }

      yield put({
        type: 'request/CLEAR_STATE',
        payload: dataToSave,
      })

      yield store.set(`app.request.loan`, encodeString(JSON.parse(JSON.stringify(dataToSave))))

      history.push('/solicitar-prestamo/error')
    }

    if (state === 4) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            createLoan: data,
          },
          origenRequest: {
            ...payload.origenRequest,
            flowStep: 'pantallaFinal',
          },
          processDate: moment().local().format('YYYY-MM-DD HH:mm:ss'),
        },
      })

      const dataToSave = {
        apiResponse: {
          createLoan: data,
        },
        lastUpdate: moment().local().format('YYYY-MM-DD HH:mm:ss'),
      }

      yield put({
        type: 'request/CLEAR_STATE',
        payload: dataToSave,
      })

      yield store.set(`app.request.loan`, encodeString(JSON.parse(JSON.stringify(dataToSave))))

      history.push('/solicitar-prestamo/sinOferta')
    }

    if (state !== 0 || state !== 1 || state !== 4) {
      yield put({
        type: 'request/SAVE_LEAD',
        payload: {
          ...payload,
          apiResponse: {
            ...payload.apiResponse,
            createLoan: data,
          },
          origenRequest: {
            ...payload.origenRequest,
            flowStep: 'pantallaFinal',
          },
          processDate: moment().local().format('YYYY-MM-DD HH:mm:ss'),
        },
      })

      const dataToSave = {
        apiResponse: {
          createLoan: data,
        },
        lastUpdate: moment().local().format('YYYY-MM-DD HH:mm:ss'),
      }

      yield put({
        type: 'request/CLEAR_STATE',
        payload: dataToSave,
      })

      yield store.set(`app.request.loan`, encodeString(JSON.parse(JSON.stringify(dataToSave))))

      history.push('/solicitar-prestamo/pantallaFinal')
    }
  }

  if (!data || typeof data?.Resolucion?.Estado === 'undefined') {
    yield isLoading(false)
    notification.warning({
      message: 'Se produjo un error, inténtelo de nuevo en unos minutos.',
    })
  }
}

export function CLEAR_STATE() {
  store.remove(`app.request.loan`)
  history.push('/solicitar-prestamo/monto')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SAVE_LEAD, SAVE_LEAD),
    takeEvery(actions.QUERY_PROPOSAL, QUERY_PROPOSAL),
    takeEvery(actions.QUERY_OFFER, QUERY_OFFER),
    takeEvery(actions.DOCUMENT_VERIFICATION, DOCUMENT_VERIFICATION),
    takeEvery(actions.MOBILE_VERIFICATION, MOBILE_VERIFICATION),
    // takeEvery(actions.IDENTITY_VERIFICATION, IDENTITY_VERIFICATION),
    takeEvery(actions.CREATE_LOAN, CREATE_LOAN),
    takeEvery(actions.CLEAR_STATE, CLEAR_STATE),
    takeEvery(actions.SET_LOADING, isLoading),
  ])
}
