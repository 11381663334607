import 'antd/lib/style/index.less' // antd core styles
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Integrations } from '@sentry/tracing'
import { logger } from 'redux-logger'
import { Provider } from 'react-redux'
import { routerMiddleware } from 'connected-react-router'
import * as Sentry from '@sentry/react'
import createSagaMiddleware from 'redux-saga'

import * as serviceWorker from './serviceWorker'
import reducers from './redux/reducers'
import Router from './router'
import sagas from './redux/sagas'

// Sentry tracking
Sentry.init({
  dsn: 'https://7c3f9457808a41d1a5f70cd14dd7c243@o545214.ingest.sentry.io/5666768',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
})

// Middlewared
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}
const store = createStore(
  reducers(history),
  compose(applyMiddleware(...middlewares), sentryReduxEnhancer),
)
sagaMiddleware.run(sagas)

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} />
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
