import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Route, Redirect, Switch } from 'react-router-dom'
import moment from 'moment'
import store from 'store'

import Layout from 'layouts'
import Loading from 'components/loans/widgets/Loading'
import { decodeString } from 'utils'

const routesPublic = [
  {
    path: '/solicitar-prestamo/monto',
    Component: lazy(() => import('pages/loan/request/amount')),
    exact: true,
  },
]

const routesPrivate = [
  {
    path: '/solicitar-prestamo/monto',
    Component: lazy(() => import('pages/loan/request/amount')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/datosPersonales1',
    Component: lazy(() => import('pages/loan/request/personalInformation1')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/montosAprobados',
    Component: lazy(() => import('pages/loan/request/amountApproved')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/fechaVencimiento',
    Component: lazy(() => import('pages/loan/request/dateDue')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/verificacionCelular',
    Component: lazy(() => import('pages/loan/request/verificationMobile')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/cambiarCelular',
    Component: lazy(() => import('pages/loan/request/changeMobile')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/verificacionIdentidad',
    Component: lazy(() => import('pages/loan/request/verificationIdentity')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/documentacion',
    Component: lazy(() => import('pages/loan/request/documentAttachment')),
    exact: true,
  }, /*
  {
    path: '/solicitar-prestamo/verificacionDocumento1',
    Component: lazy(() => import('pages/loan/request/verificationDocumentSelectCiType')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/verificacionDocumento2',
    Component: lazy(() => import('pages/loan/request/verificationDocumentValidateCod')),
    exact: true,
  }, */
  {
    path: '/solicitar-prestamo/adicionales',
    Component: lazy(() => import('pages/loan/request/insurances')),
    exact: true,
  }, 
  {
    path: '/solicitar-prestamo/pantallaFinal',
    Component: lazy(() => import('pages/loan/request/finish')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/sinOferta',
    Component: lazy(() => import('pages/loan/request/errorScreens/rejected')),
    exact: true,
  },
  {
    path: '/solicitar-prestamo/error',
    Component: lazy(() => import('pages/loan/request/errorScreens/error')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const isRequest = () => {
  const localStorage = store.get('app.request.loan')
  const { lastUpdate = '' } = localStorage
    ? decodeString(JSON.parse(JSON.stringify(localStorage)))
    : {}
  const lastUpdateParse = moment(lastUpdate, 'YYYY-MM-DD HH:mm:ss')

  if (lastUpdate === '' || moment(lastUpdateParse.add(10, 'minutes')).isSameOrBefore(moment()))
    return false // dejar en false
  return true
}

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/solicitar-prestamo/monto" />}
                    />
                    {isRequest()
                      ? routesPrivate.map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={<Loading />}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      ))
                      : routesPublic.map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={<Loading />}>
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      ))}
                    <Redirect to="/solicitar-prestamo/monto" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
